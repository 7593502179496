<template>
  <div v-if="showFilter">
    <div
      class="v-overlay__scrim"
      style="z-index: 5; opacity: 0.46;background-color: rgb(255 255 255 / 0%);border-color: rgb(33, 33, 33); position:fixed;"
      @click="$emit('closeFilter')"
    ></div>
    <v-expand-x-transition>
      <!-- v-click-outside="closeFilter" -->
      <!-- @focusout="$emit('closeFilter')" -->
      <!-- { status: showFilter, callback: $emit('closeFilter2') }-->
      <div
        style="position: absolute; z-index: 5; margin-left: -400px;"
        @keyup.esc="$emit('closeFilter')"
        tabindex="0"
        :class="`${classfilters}`"
      >
        <v-card tile outlined elevation="0" width="400" ref="modalfilter">
          <v-card-title class="pa-1 px-4 d-flex justify-space-between">
            <div>Filtros</div>
            <div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="mr-4" @click="clearFilter" dense>mdi-filter-remove</v-icon>
                </template>
                <span>Reestablecer Filtros</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="red" @click="$emit('closeFilter')">mdi-close</v-icon>
                </template>
                <span>Cancelar</span>
              </v-tooltip>
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="px-3 pt-5 pb-0">
            <div class="px-5">
              <v-select
                v-model="selectedAuxTipoPresupuesto"
                :items="itemsTiposPresupuesto"
                return-object
                label="Tipo de Presupuesto"
                dense
              >
              </v-select>
            </div>
            <v-divider class="mb-6"></v-divider>
            <div class="px-5">
              <v-select v-model="selectedAuxSedes" :items="itemsSedes" label="Sede" dense multiple return-object>
                <template v-slot:selection="{ item, index }">
                  <div v-if="selectedAuxSedes.length < itemsSedes.length" class="d-flex">
                    <span v-if="index < 2">{{ item.text }}</span>
                    <span v-if="selectedAuxSubAreas.length > 1 && index < 1">,&nbsp;</span>
                    <span v-if="index == 2" class="ml-2 grey--text text-caption">
                      (+{{ `${selectedAuxSedes.length - 2 > 1 ? 'Otros ' : ''}${selectedAuxSedes.length - 2}` }})
                    </span>
                  </div>
                  <span v-else-if="index == 0">
                    TODOS
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="selectAll(selectedAuxSedes, itemsSedes, 'S')" @mousedown.prevent>
                    <v-list-item-action>
                      <v-icon color="primary">{{ iconCheckboxAll(selectedAuxSedes, itemsSedes) }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> TODOS </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </div>
            <v-divider class="mb-6"></v-divider>
            <div class="px-5">
              <v-select v-model="selectedAuxSubAreas" :items="itemsSubAreas" label="Área" dense multiple return-object>
                <template v-slot:selection="{ item, index }">
                  <div v-if="selectedAuxSubAreas.length < itemsSubAreas.length" class="d-flex">
                    <span v-if="index < 2">{{ item.codigo }}</span>
                    <span v-if="selectedAuxSubAreas.length > 1 && index < 1">,&nbsp;</span>
                    <span v-if="index == 2" class="ml-2 grey--text text-caption">
                      (+{{ `${selectedAuxSubAreas.length - 2 > 1 ? 'Otros ' : ''}${selectedAuxSubAreas.length - 2}` }})
                    </span>
                  </div>
                  <span v-else-if="index == 0">
                    TODOS
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="selectAll(selectedAuxSubAreas, itemsSubAreas, 'SA')" @mousedown.prevent>
                    <v-list-item-action>
                      <v-icon color="primary">{{ iconCheckboxAll(selectedAuxSubAreas, itemsSubAreas) }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> TODOS </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </div>
            <v-divider class="mb-6"></v-divider>
            <div class="px-5">
              <v-select
                v-model="selectedAuxCuentasMayores"
                :items="itemsCuentasMayores"
                label="Cuenta Mayor"
                dense
                multiple
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <div v-if="selectedAuxCuentasMayores.length < itemsCuentasMayores.length" class="d-flex">
                    <span v-if="index < 2">{{ item.descripcion }}</span>
                    <span v-if="selectedAuxCuentasMayores.length > 1 && index < 1">,&nbsp;</span>
                    <span v-if="index == 2" class="ml-2 grey--text text-caption">
                      (+{{
                        `${selectedAuxCuentasMayores.length - 2 > 1 ? 'Otros ' : ''}${selectedAuxCuentasMayores.length -
                          2}`
                      }})
                    </span>
                  </div>
                  <span v-else-if="index == 0">
                    TODOS
                  </span>
                </template>
                <template v-slot:prepend-item>
                  <v-list-item
                    ripple
                    @click="selectAll(selectedAuxCuentasMayores, itemsCuentasMayores, 'CM')"
                    @mousedown.prevent
                  >
                    <v-list-item-action>
                      <v-icon color="primary">{{
                        iconCheckboxAll(selectedAuxCuentasMayores, itemsCuentasMayores)
                      }}</v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> TODOS </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </div>
            <v-divider class="mb-6"></v-divider>
            <div class="px-5 d-flex">
              <v-select
                v-model="periodoAuxInicio"
                :items="itemsPeriodos"
                class="mr-5"
                label="Periodo Inicio"
                dense
                :return-object="false"
              >
              </v-select>
              <v-select
                v-model="periodoAuxFin"
                :items="itemsPeriodos"
                label="Periodo Fin"
                dense
                :return-object="false"
              ></v-select>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="filtrar"> Aceptar </v-btn>
          </v-card-actions>
          <v-divider></v-divider>
        </v-card>
      </div>
    </v-expand-x-transition>
  </div>
</template>
<script>
export default {
  name: 'FilterGraficos',
  props: {
    panelTotales: {
      type: Array,
      default: () => []
    },
    itemsTiposPresupuesto: {
      type: Array,
      default: () => []
    },
    selectedTipoPresupuesto: {
      type: Object,
      default: null
    },
    itemsSedes: {
      type: Array,
      default: () => []
    },
    selectedSedes: {
      type: Array,
      default: () => []
    },
    itemsSubAreas: {
      type: Array,
      default: () => []
    },
    selectedSubAreas: {
      type: Array,
      default: () => []
    },
    itemsCuentasMayores: {
      type: Array,
      default: () => []
    },
    selectedCuentasMayores: {
      type: Array,
      default: () => []
    },
    itemsPeriodos: {
      type: Array,
      default: () => []
    },
    periodoInicio: {
      type: Number,
      default: null
    },
    periodoFin: {
      type: Number,
      default: null
    },
    showFilter: {
      type: Boolean,
      default: false
    },
    classfilters: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    selectedAuxTipoPresupuesto: null,
    selectedAuxSedes: [],
    selectedAuxSubAreas: [],
    selectedAuxCuentasMayores: [],
    periodoAuxInicio: null,
    periodoAuxFin: null
  }),
  watch: {
    showFilter() {
      this.selectedAuxTipoPresupuesto = this.selectedTipoPresupuesto;
      this.selectedAuxSedes = this.selectedSedes;
      this.selectedAuxSubAreas = this.selectedSubAreas;
      this.selectedAuxCuentasMayores = this.selectedCuentasMayores;
      this.periodoAuxInicio = this.periodoInicio;
      this.periodoAuxFin = this.periodoFin;
    }
  },
  computed: {},
  methods: {
    async clearFilter() {
      this.selectedAuxTipoPresupuesto = this.itemsTiposPresupuesto[0];
      this.selectedAuxSedes = this.itemsSedes;
      this.selectedAuxSubAreas = this.itemsSubAreas;
      this.selectedAuxCuentasMayores = this.itemsCuentasMayores;
      if (this.itemsPeriodos.length <= 12) this.periodoAuxInicio = this.itemsPeriodos[0].value;
      else this.periodoAuxInicio = this.itemsPeriodos[this.itemsPeriodos.length - 13].value;
      this.periodoAuxFin = this.itemsPeriodos[this.itemsPeriodos.length - 1].value;
    },
    checkAll(selected, items) {
      return (selected?.length ?? 0) === items.length;
    },
    checkSome(selected, items) {
      return (selected?.length ?? 0) > 0 && !this.checkAll(selected, items);
    },
    iconCheckboxAll(selected, items) {
      if (this.checkAll(selected, items)) return 'mdi-close-box';
      if (this.checkSome(selected, items)) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
    selectAll(selected, items, tipo) {
      this.$nextTick(() => {
        if (this.checkAll(selected, items)) {
          if (tipo == 'S') this.selectedAuxSedes = [];
          else if (tipo == 'SA') this.selectedAuxSubAreas = [];
          else if (tipo == 'CM') this.selectedAuxCuentasMayores = [];
        } else {
          if (tipo == 'S') this.selectedAuxSedes = this.itemsSedes;
          else if (tipo == 'SA') this.selectedAuxSubAreas = this.itemsSubAreas;
          else if (tipo == 'CM') this.selectedAuxCuentasMayores = this.selectedCuentasMayores;
        }
      });
    },
    filtrar() {
      let filtro = {
        selectedTipoPresupuesto: this.selectedAuxTipoPresupuesto,
        selectedSedes: this.selectedAuxSedes,
        selectedSubAreas: this.selectedAuxSubAreas,
        selectedCuentasMayores: this.selectedAuxCuentasMayores,
        periodoInicio: this.periodoAuxInicio,
        periodoFin: this.periodoAuxFin
      };
      this.$emit('filtrar', filtro);
    }
  }
};
</script>

<style lang="scss">
.filters-expand {
  position: fixed !important;
  z-index: 6 !important;
  margin-left: calc(100% - 320px);
  margin-top: -147px;
}

.filters-not-expand {
  position: fixed !important;
  z-index: 6 !important;
  margin-left: calc(100% - 320px);
  margin-top: -147px;
}
</style>
